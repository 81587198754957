import type { Gift } from '@consumer/types/serializers'
import { presence, isPresent } from '@shared/object'
import { ANONYMOUS_SENDER_NAME, ANONYMOUS_RECIPIENT_NAME } from '@consumer/constants/gift'

export function greetingMessage (gift: Gift) {
  return presence(gift.greeting.message)
}
export function greetingFrom (gift: Gift) {
  const from = gift.greeting.from
  if (isPresent(from) && from !== ANONYMOUS_SENDER_NAME) {
    return from
  }
}

export function greetingTo (gift: Gift) {
  const to = gift.greeting.to
  if (isPresent(to) && to !== ANONYMOUS_RECIPIENT_NAME) {
    return to
  }
}
