import type { MaybeRefOrGetter } from 'vue'
import type GiftTemplate from '@consumer/types/GiftTemplate'
import type { RecipientGift } from '@consumer/types/serializers'

import tinycolor from 'tinycolor2'
import { addBusinessDays, addDays, isAfter } from 'date-fns'
import { generateDefaultTagline, getTagline } from '@consumer/logic/giftly'
import { RedemptionMethod } from '@consumer/types/RedemptionMethod'
import { formatCurrency } from '@consumer/utils/currency'
import { parseDate } from '@consumer/utils/date'
import { greetingMessage, greetingFrom, greetingTo } from '@consumer/logic/greetingCard'

export enum GiftState {
  INITIALIZED = 'initialized',
  PURCHASE_FLAGGED = 'purchase_flagged',
  PURCHASE_DENIED = 'purchase_denied',
  PURCHASED = 'purchased',
  DELIVERED = 'delivered',
  ACTIVATED = 'activated',
  USED = 'used',
  REDEMPTION_REQUESTED = 'redemption_requested',
  REDEMPTION_REJECTED = 'redemption_rejected',
  REDEEMED = 'redeemed',
  REFUNDED = 'refunded',
  CANCELLED = 'cancelled',
}

export enum GiftRedemptionConfirmationStatus {
  CONFIRMED = 'confirmed',
  EXPIRED = 'expired',
  PENDING = 'pending',
  RESET = 'reset',
}

export function isDelivered (gift: GiftTemplate) {
  return gift?.state === GiftState.DELIVERED
}

export function useGift (maybeGiftRef: MaybeRefOrGetter<GiftTemplate & Partial<RecipientGift> | undefined>) {
  const giftRef = toRef(maybeGiftRef)
  const gift = $(giftRef)

  const formattedAmount = computed(() => {
    if (gift && gift.amount && /^\d+$/.test(gift.amount.toString())) {
      return formatCurrency(gift.amount)
    }
    else {
      return gift?.amount
    }
  })

  const tagline = computed(() => gift && (getTagline(gift) || generateDefaultTagline(gift)))

  const placeCount = computed(() => gift?.places?.length ?? 1)

  const placeName = computed(() => {
    const place = gift?.places?.[0]
    return place?.name || 'Any Place'
  })

  const allPlaceNames = computed(() => gift?.places?.map(place => place.name).join(', '))

  const allPlaceNamesOr = computed(() => {
    const names = gift?.places?.map(place => place.name) ?? []
    return [names.slice(0, -1).join(', '), names.slice(-1)].filter(Boolean).join('or')
  })

  const hasMultiplePlaces = computed(() => placeCount.value > 1)

  // Greeting card
  const messageForRecipient = computed(() => gift && greetingMessage(gift as RecipientGift))
  const senderName = computed(() => gift && greetingFrom(gift as RecipientGift))
  const recipientName = computed(() => gift && greetingTo(gift as RecipientGift))
  const isGreetingCardBlank = computed(() => (
    !messageForRecipient.value
    && !recipientName.value
    && !senderName.value
  ))

  // States
  const isDelivered = computed(() => gift?.state === GiftState.DELIVERED)
  const isActivated = computed(() => gift?.state === GiftState.ACTIVATED)
  const isRedeemed = computed(() => gift?.state === GiftState.REDEEMED)
  const isRedemptionRequested = computed(() => gift?.state === GiftState.REDEMPTION_REQUESTED)
  const isCancelled = computed(() => gift?.state === GiftState.CANCELLED)
  const isRefunded = computed(() => gift?.state === GiftState.REFUNDED)
  const isCancelledOrRefunded = computed(() => isCancelled.value || isRefunded.value)

  // Redemption Confirmation Status
  const isRedemptionConfirmationPending = computed(() => (
    isRedemptionRequested.value && !isRedemptionConfirmationComplete.value
  ))
  const isRedemptionConfirmationComplete = computed(() => (
    gift?.redemptionConfirmationStatus === GiftRedemptionConfirmationStatus.CONFIRMED
  ))
  const isRedemptionConfirmationExpired = computed(() => (
    gift?.redemptionConfirmationStatus === GiftRedemptionConfirmationStatus.EXPIRED
  ))

  const isThankYouSent = computed(() => Boolean(gift?.thankYouCard))

  const redemptionExpectedArrivalDate = computed(() => {
    if (!gift?.redemptionCreditSentAt) {
      return null
    }
    else if (gift.redemptionProcessorName === RedemptionMethod.ACH) {
      return addBusinessDays(parseDate(gift.redemptionCreditSentAt), 3)
    }
    else if (gift.redemptionProcessorName === RedemptionMethod.PayPal) {
      return addBusinessDays(parseDate(gift.redemptionCreditSentAt), 4)
    }
    else if (gift.redemptionProcessorName === RedemptionMethod.PerfectGift) {
      return addDays(parseDate(gift.redemptionCreditSentAt), 14)
    }
  })

  const isRedemptionCreditSent = computed(() => Boolean(gift?.redemptionCreditSentAt))

  const isRedemptionCreditReceived = computed(() => (
    isRedeemed.value && redemptionExpectedArrivalDate.value && isAfter(new Date(), redemptionExpectedArrivalDate.value)
  ))

  const redemptionMethod = computed(() => {
    if (gift?.redemptionProcessorName === RedemptionMethod.ACH) {
      return 'Bank Account'
    }
    else if (gift?.redemptionProcessorName === RedemptionMethod.PayPal) {
      return 'PayPal'
    }
    else if (gift?.redemptionProcessorName === RedemptionMethod.PerfectGift) {
      return 'Visa&reg; card'
    }
  })

  const primaryBgColor = computed(() => gift?.giftlyMetadata?.themeColor ?? '#208DCA')
  const primaryTextColor = computed(() => tinycolor(primaryBgColor.value).isLight() ? '#111E36' : '#FFF')
  const secondaryBgColor = computed(() => (
    tinycolor(primaryBgColor.value).isLight()
      ? tinycolor(primaryBgColor.value).darken(50).toHexString()
      : tinycolor(primaryBgColor.value).lighten(40).toHexString()
  ))
  const secondaryTextColor = computed(() => tinycolor(secondaryBgColor.value).isLight() ? '#111E36' : '#FFF')
  const complementaryBgColor = computed(() => tinycolor(primaryBgColor.value).complement().toHexString())

  const hasGiftlyImage = computed(() => (
    Boolean(gift?.giftlyImageUrl)
    || (gift?.giftlyVersion && gift?.giftlyVersion >= 2)
  ))

  return {
    gift: giftRef,
    formattedAmount,
    tagline,
    placeCount,
    placeName,
    allPlaceNames,
    allPlaceNamesOr,
    hasMultiplePlaces,
    messageForRecipient,
    senderName,
    recipientName,
    isGreetingCardBlank,
    isDelivered,
    isActivated,
    isRedemptionRequested,
    isRedeemed,
    isCancelled,
    isRefunded,
    isCancelledOrRefunded,
    isRedemptionConfirmationPending,
    isRedemptionConfirmationComplete,
    isRedemptionConfirmationExpired,
    isRedemptionCreditSent,
    isRedemptionCreditReceived,
    isThankYouSent,
    redemptionMethod,
    redemptionExpectedArrivalDate,
    primaryBgColor,
    primaryTextColor,
    secondaryBgColor,
    secondaryTextColor,
    complementaryBgColor,
    hasGiftlyImage,
  }
}
